*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

button {
  background-color: #0000;
  background-image: none;
}

fieldset {
  margin: 0;
  padding: 0;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  font-family: inherit;
  line-height: inherit;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

hr {
  border-top-width: 1px;
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #cbd5e0;
}

button {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button, input, optgroup, select, textarea {
  line-height: inherit;
  color: inherit;
  padding: 0;
}

pre, code, kbd, samp {
  font-family: Inconsolata, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-border-opacity: 1;
  border-color: rgba(237, 242, 247, var(--tw-border-opacity));
}

*, :before, :after {
  --animation-duration: 1s;
  --animation-iteration-count: infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.card-button {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgba(45, 55, 72, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(45, 55, 72, var(--tw-bg-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(198, 246, 213, var(--tw-text-opacity));
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  justify-content: center;
  align-items: center;
  padding: .5rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.card-button:hover {
  --tw-border-opacity: 1;
  border-color: rgba(45, 55, 72, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(45, 55, 72, var(--tw-text-opacity));
  background-color: #0000;
}

.card-button:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.bio-button {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgba(45, 55, 72, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(45, 55, 72, var(--tw-bg-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(229, 250, 104, var(--tw-text-opacity));
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  font-size: 1.5rem;
  line-height: 2rem;
  line-height: inherit;
  margin-top: .75rem;
  margin-bottom: .75rem;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.bio-button:hover {
  --tw-border-opacity: 1;
  border-color: rgba(45, 55, 72, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(45, 55, 72, var(--tw-text-opacity));
  background-color: #0000;
}

.bio-button:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.z-10 {
  z-index: 10;
}

.m-1 {
  margin: .25rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-auto {
  margin-left: auto;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.h-12 {
  height: 3rem;
}

.min-h-screen {
  min-height: 100vh;
}

.w-5 {
  width: 1.25rem;
}

.w-12 {
  width: 3rem;
}

.w-1\/2 {
  width: 50%;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-full {
  width: 100%;
}

.max-w-screen-md {
  max-width: 768px;
}

.max-w-screen-lg {
  max-width: 1024px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-around {
  justify-content: space-around;
}

.rounded-md {
  border-radius: .375rem;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-transparent {
  border-color: #0000;
}

.border-current, .hover\:border-current:hover {
  border-color: currentColor;
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(113, 128, 150, var(--tw-bg-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 85, 104, var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(198, 246, 213, var(--tw-bg-opacity));
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(154, 230, 180, var(--tw-bg-opacity));
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(104, 211, 145, var(--tw-bg-opacity));
}

.bg-teal-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 245, 234, var(--tw-bg-opacity));
}

.bg-canary {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 250, 104, var(--tw-bg-opacity));
}

.fill-current {
  fill: currentColor;
}

.p-2 {
  padding: .5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.text-center {
  text-align: center;
}

.font-sans {
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-mono {
  font-family: Inconsolata, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.leading-normal {
  line-height: 1.5;
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(45, 55, 72, var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(26, 32, 44, var(--tw-text-opacity));
}

.text-green-200 {
  --tw-text-opacity: 1;
  color: rgba(198, 246, 213, var(--tw-text-opacity));
}

.text-teal-200 {
  --tw-text-opacity: 1;
  color: rgba(178, 245, 234, var(--tw-text-opacity));
}

.underline, .hover\:underline:hover {
  text-decoration: underline;
}

*, :before, :after {
  --tw-shadow: 0 0 #0000;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

*, :before, :after {
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #4299e180;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.text-shadow {
  text-shadow: 0 2px 5px #00000080;
}

.text-shadow-none {
  text-shadow: none;
}

.caps-small {
  font-variant-caps: small-caps;
}

.animation-blink, .group:hover .group-hover\:animation-blink {
  animation-name: blink;
  animation-duration: 1s;
  animation-duration: var(--animation-duration);
  animation-iteration-count: infinite;
  animation-iteration-count: var(--animation-iteration-count);
}

.animation-2s {
  --animation-duration: 2s;
  animation-duration: 2s;
  animation-duration: var(--animation-duration);
}

.animate__animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animate__fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.scroll-smooth {
  scroll-behavior: smooth;
}

@media (min-width: 640px) {
  .sm\:scroll-smooth {
    scroll-behavior: smooth;
  }
}

@media (min-width: 768px) {
  .md\:fixed {
    position: fixed;
  }

  .md\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:-mt-20 {
    margin-top: -5rem;
  }

  .md\:mr-10 {
    margin-right: 2.5rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-2\/5 {
    width: 40%;
  }

  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:w-12\/25 {
    width: 48%;
  }

  .md\:flex-wrap {
    flex-wrap: wrap;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-6xl {
    font-size: 4rem;
  }

  .md\:animation-none {
    animation-name: none;
  }

  .md\:scroll-smooth {
    scroll-behavior: smooth;
  }
}

@media (min-width: 1024px) {
  .lg\:w-11\/12 {
    width: 91.6667%;
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:scroll-smooth {
    scroll-behavior: smooth;
  }
}

@media (min-width: 1280px) {
  .xl\:scroll-smooth {
    scroll-behavior: smooth;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:scroll-smooth {
    scroll-behavior: smooth;
  }
}

/*# sourceMappingURL=index.9d51b2aa.css.map */
